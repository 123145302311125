// Service for saving and retrieving form configurations from the backend.

export const textJson = (formFields) => {
  const text_fields = formFields.reduce((acc, field) => {
    acc[field.key] = {
      key: field.key,
      type: field.type,
      hebrew_name: field.hebrewLabel,
      require: field.required,
      order: field.order,
    };
    if (field.dependsOn && field.condition) {
      acc[field.key].dependencies = [{field: field.dependsOn, condition: field.condition}]
    }
    return acc;
  }, {});

  return text_fields;
};

export const fileJson = (formFields) => {
  const file_fields = formFields.reduce((acc, field) => {
    acc[field.key] = {
      hebrew_name: field.hebrewLabel,
      require: field.required,
      order: field.order,
      max: field.max,
      private: field.private,
      comment: field.comment || null,
    };
    return acc;
  }, {});

  return file_fields;
};
  